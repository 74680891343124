import React from 'react';
import Image from 'reusecore/src/elements/Image';
import DocumentationSection from '../../../containers/SaasModern/Documentation';
import insert_date_1 from '../../../../../common/src/assets/image/insert_date_1.png';
import insert_date_2 from '../../../../../common/src/assets/image/insert_date_2.png';
import insert_date_5 from '../../../../../common/src/assets/image/insert_date_5.png';

const Documentation = () => {
  const content = (
    <div>
      <p>
        PrintableCal provides a date picker in Microsoft Word, Excel, and
        PowerPoint. When a date is clicked, that date will be inserted at the
        current cell (Excel) or text cursor location (Word and PowerPoint).{' '}
      </p>
      <p>
        To make the date picker visible, click the <strong>Date</strong> button
        on the PrintableCal tab of the ribbon.
        <br />
        <Image alt="" src={insert_date_1} style={{ width: 795 }} />
      </p>
      <p>
        To insert a date, click or double-click the desired date in the date
        picker. <br />
        <Image alt="" src={insert_date_2} style={{ width: 590 }} />
      </p>
      <h2>Settings</h2>
      <table className="table">
        <tbody>
          <tr>
            <td>
              <strong>Date format</strong>
            </td>
            <td>
              The inserted date format can be adjusted to use the system default
              format, a short format, or a long format. Each format will depend
              upon the regional settings specified in the Windows Control Panel.
              For example, when Windows is configured to use the English (United
              States) regional format, the inserted dates will be formatted as
              shown below.
              <br />
              <Image alt="" src={insert_date_5} style={{ width: 266 }} />
            </td>
          </tr>
          <tr>
            <td>
              <strong>Date selection mode</strong>
            </td>
            <td>
              {' '}
              Dates can be selected from the data picker by either single
              clicking or double clicking.
            </td>
          </tr>
          <tr>
            <td>
              <strong>Active cell after inserting</strong>
            </td>
            <td>
              This setting is only available in Excel. It controls which cell
              becomes active after picking a date. Choices are{' '}
              <strong>Activate cell in next row</strong>,{' '}
              <strong>Activate cell in next column</strong>, or{' '}
              <strong>Do not change active cell</strong>.
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  );

  return (
    <DocumentationSection
      url="https://www.printablecal.com/Documentation/Insert-Date"
      commentsId="commentsplus_post_149_489"
      title="Insert a Date At the Current Cell or Text Location"
      description="PrintableCal provides a date picker for Word and Excel. A date can be clicked to be inserted at the current cell or text cursor location."
      keywords="date picker, mini calendar, task pane, insert date, Excel calendar, Word calendar, calendar template, calendar add-in, 2020 calendar, printable calendar"
      content={content}
    />
  );
};

export default Documentation;
